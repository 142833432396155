<ng-container>
  <app-cms-header></app-cms-header>
  <div class="container-fluid screen-height mt-5">
    <app-terms [content]="data" *ngIf="screenType === 'termsAndConditions'"></app-terms>
    <app-privacy [content]="data" *ngIf="screenType === 'privacyPolicy'"></app-privacy>
    <app-faq [content]="data" *ngIf="screenType === 'faq'"></app-faq>
    <app-support [content]="data" *ngIf="screenType === 'supportAndContact'"></app-support>
    <app-about-us [content]="data" *ngIf="screenType === 'aboutUs'"></app-about-us>
    <app-delete-account [content]="data" *ngIf="screenType === 'deleteAccount'"></app-delete-account>
  </div>
  <app-footer></app-footer>
</ng-container>
