import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public route: Router){}
  userType: string = ''
  ngOnInit(): void {
    if(this.route.url.includes('driver')){
      this.userType= 'driver';
    }else{
      this.userType = 'passenger'
    }
  }
  aboutUs(){
    this.route.navigate([`/cms/${this.userType}/aboutUs`])
  }
  termsAndCondition(){
    this.route.navigate([`/cms/${this.userType}/termsAndConditions`])
  }
  privacyPolicy(){
    this.route.navigate([`/cms/${this.userType}/privacyPolicy`])
  }
  faq(){
    this.route.navigate([`/cms/${this.userType}/faq`])
  }
  contactUs(){
    this.route.navigate([`/cms/${this.userType}/supportAndContact`])
  }
  deleteAccount(){
    this.route.navigate([`/cms/${this.userType}/deleteAccount`])
  }
}
