<div class="container">
  <div class="row">
    <div class="col-md-10 m-auto">
      <!-- <div [innerHTML]="content"></div> -->
      <div>
        <h1 class="mb-3">Delete Account</h1>

        <div class="mb-2">
          <h6>Step 1</h6>
          Login in application <br>
        </div>
        <div class="mb-2">
          <h6>Step 2</h6>

          Move to side drawer menu
        </div>

        <div class="mb-2">

          <h6>Step 3</h6>

          Scroll to menu item Delete my profile
        </div>

        <div class="mb-2">

          <h6>Step 4</h6>

          Click on it, you will get a pop-up
        </div>

        <div class="mb-2">
          <h6>Step 5</h6>

          Read carefully and proceed with you choice
        </div>
        <div class="mb-2">
          <h6>Step 6</h6>

          On your confirmation on Yes, Delete , your account will be deleted
        </div>
      </div>

    </div>
  </div>
</div>