export enum PageType {
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_CONDITIONS = 'termsAndConditions',
  ABOUT_US = 'aboutUs',
  FAQ = 'faq',
  SUPPORT_AND_CONTACT = 'supportAndContact',
  DELETE_ACCOUNT = 'deleteAccount'
}

export type PageTypes = 'privacyPolicy' | 'termsAndConditions' | 'aboutUs' | 'faq' | 'deleteAccount';
